import React, { useEffect, useState } from "react";
import {
  Container,
  Tab,
  Tabs,
  Row,
  Col,
  Button,
  Card,
  Image,
  Modal,
  ModalHeader,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
// import { Col, Row, Dropdown } from "react-bootstrap";
import {
  BsFillStarFill,
  BsFillCalendarFill,
  BsFillShareFill,
  BsFillPlayCircleFill,
  BsPlayFill,
} from "react-icons/bs";
import OverView from "./Details/OverView";
import Seasons from "./Details/Seasons";
import "./Details.css"; // Import your CSS file
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import instance, { APP_COLORS, APP_IMAGES } from "./common/Api";
import AgePop from "./PopUp/AgePop";
import VideoJsPlayer from "./Player/VideoJsPlayer";
import MobilePlayer from "./Player/MobilePlayer";
import CommonListing from "./common/CommonListing";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Loader from "./common/Loader";

const Details = (props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("view1");
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [mediaData, setMediaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDialogAge, setShowDialogAge] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [types, setTypes] = useState(null);
  const [cookies, setCookies] = useCookies();
  const [showPlayer, setShowPlayer] = useState(false);
  const [playerPayload, setPlayerPayload] = useState(null);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoPoster, setSelectedVideoPoster] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [seasonsActiveTab, setSeasonsActiveTab] = useState("view1");
  const [watchNext, setWatchNext] = useState(null);

  // props.userData;
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }
  const location = useLocation();
  const { someData } = location.state;
  const { data } = useParams();
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleAge = (value) => {
    // if (isUserRegitered) {
    // //console.log("value", value, "someData:", mediaData, "type:", types);
    if (value == "yes") {
      // console.warn("Test");
      navigate("/play", { state: { someData: mediaData, type: types } });
      // navigate("/media-video", { state: { src: mediaData.main_video } });
    }
    // }
  };

  const acceptWarning = () => {
    if (isMobile) {
      navigate("/play", { state: { someData: mediaData, type: types } });
    } else {
      navigate("/play", {
        state: { src: selectedVideo, poster: selectedVideoPoster },
      });
    }
  };

  const playVideo = (src, poster) => {
    setSelectedVideo(src);
    setSelectedVideoPoster(poster);
    if (props.userData != null) {
      // //console.log("handleClick");
      if (isSubscribed) {
        if (mediaData && mediaData.age === "18+") {
          //console.log("handleClick", data);
          setShowDialogAge(true);
        } else {
          navigate("/play", {
            state: { src: src, poster: poster },
          });
        }
      } else {
        // navigate("/play", {
        //   state: { src: src, poster: poster },
        // });
        navigate("/media/");
      }
    } else {
      navigate("/login");
    }

    // if (props.userData != null) {
    //   if (mediaData && mediaData.age === "18+") {
    //     //console.log("handleClick");
    //     setShowDialogAge(true);
    //   } else {
    //     navigate("/play", {
    //       state: { src: src, poster: poster },
    //     });
    //   }
    // } else {
    //   navigate("/login");
    // }
  };

  const handleClick = () => {
    setTypes("main");
    if (props.userData != null) {
      if (mediaData && mediaData.age === "18+") {
        if (isMobile) {
          if (isSubscribed) {
            setShowDialogAge(true);
          } else {
            navigate("/subscribe");
          }
        } else {
          setShowDialogAge(true);
        }
      } else {
        navigate("/play", { state: { someData: mediaData, type: types } });
        // navigate("/media-video", { state: { src: mediaData.main_video } });
      }
    } else {
      navigate("/login");
    }
  };

  const handleClickSeasons = (payload) => {
    setTypes("main");
    setPlayerPayload(payload);
    // setTypes("main");
    // navigate("/play", { state: { someData: payload, type: "main" } });
    if (props.userData != null) {
      if (mediaData && mediaData.age === "18+") {
        setShowDialogAge(true);
      } else {
        navigate("/play", { state: { someData: playerPayload, type: "main" } });
      }
    } else {
      navigate("/subscribe");
    }
  };
  const handleClickTrailer = () => {
    setTypes("trailer");
    if (props.userData != null) {
      if (mediaData && mediaData.age === "18+") {
        if (isMobile) {
          if (isSubscribed) {
            setShowDialogAge(true);
          } else {
            navigate("/subscribe");
          }
        } else {
          setShowDialogAge(true);
        }
      } else {
        navigate("/play", { state: { someData: mediaData, type: types } });
      }
    } else {
      navigate("/login");
    }
  };

  const getCastNames = (cast) => {
    // console.warn(cast);
    return cast.map((item) => item.name).join(" | ");
  };
  const formatGenres = (genres) => {
    if (!genres || genres.length === 0) {
      return null;
    }
    return genres.join(", ");
  };

  const checkSubscribtion = () => {
    if (props.userData != null) {
      instance
        .post(`/subscription_plans_web?id=${props.userData.id}&ip=${props.ip}`)
        .then((response) => {
          //console.log("response.data", response.data);
          const active = response.data.active_plan;
          // console.log(active[0], "<active");
          if (response.data && response.data.active_plan.length > 0) {
            // setActivePlans(response.data.active_plan[0]);
            setIsSubscribed(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserDetails(props.userData);
    // if (props.userData !== null) {
    //   setIsSubscribed(props.userData.isSubscribed);
    // }
    checkSubscribtion();

    // console.warn("DETAILS IN USERDATA:", data);
    if (someData != null) {
      //console.log("DETAILS:", someData);
      //console.log("Video_Id", someData.id);
      //console.log("Title", someData.title);
    }
    instance
      .get(
        `/videoViewBySlug?titleYearSlug=${someData.title}&videoid=${someData.id}&ip=${props.ip}`
      )
      .then((response) => {
        setMediaData(response.data);
        //console.log("response.data", response.data);
        //console.log("Again DAta", mediaData.seasons);
        setCurrentVideoUrl(mediaData.video);
        const adminVidId = response.data.admin_video_id;
        watchNextApi(adminVidId);
        // setMediaData(response.data);
        // navigate("/play", { state: { someData: mediaData, type: "main" } });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });
    // if (mediaData != null) {
    //   //console.log("mediaData;;;;;;;", mediaData);
    // }
    console.error("userDetails..........", userDetails);
  }, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }
    (async function () {
      let response = await instance.get("/getAllCookies");
      //console.log("res", response);
      response.data.forEach((obj) => {
        const { key, value } = obj;
        setCookies(key, value, { path: "/", domain: "kannadaflix.com" });
      });
      //console.log(cookies);
    })();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const watchNextApi = (adminVidId) => {
    instance
      .post(
        `/suggestions?id=${
          props.userData && props.userData.id != null ? props.userData.id : "1"
        }&admin_video_id=${adminVidId && adminVidId}&skip=1`
      )
      .then((response) => {
        //console.log(response.data.data);
        setWatchNext(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // setLoadingNext(false);
      });
  };

  const createVideoData = (item) => {
    // console.log(item.title, item.admin_video_id);
    if (props.userData != null) {
      if (isSubscribed) {
        setLoading(true);
        instance
          .get(
            `/videoViewBySlug?titleYearSlug=${item.title}&videoid=${item.id}`
          )
          .then((response) => {
            setMediaData(response.data);
            const adminVidId = response.data.admin_video_id;
            watchNextApi(adminVidId);
            // console.log("response.data: createVideoData>", response.data);
            navigate("/play", { state: { someData: response.data } });
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            // if (mediaData !== null) {
            //   navigate("/play", { state: { someData: mediaData } });
            // }
            // setLoading(false);
            // Set loading to false when fetch is complete
          });
      } else {
        navigate("/subscribe");
      }
    } else {
      navigate("/login");
    }
  };

  const backgroundStyle = {
    position: "relative",
    backgroundImage: ``,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  };

  const handleClosePop = () => {
    setShowDialogAge(false);
  };

  // Function to handle listing click
  const handleListingClick = (imageUrl) => {
    // Do something with the clicked image URL
    //console.log("Clicked image URL:", imageUrl);
  };

  return (
    <>
      {showDialogAge && (
        <Modal
          show={showDialogAge}
          onHide={handleClosePop}
          style={{ marginTop: "10rem" }}
        >
          <Modal.Body
            style={{
              backgroundImage: `url(${APP_IMAGES.POP_UP_BG})`,
              backgroundSize: "cover",
              color: "white",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "20px",

              // height: "15rem", // Adjust as needed
              backgroundColor: "black",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  paddingBottom: "1rem",
                  width: "50px",
                  height: "50px",
                  textAlign: "center", // Center text horizontally
                  lineHeight: "50px", // Center text vertically
                  backgroundColor: "white",
                  borderRadius: "50%",
                  color: "black",
                }}
              >
                A
              </h1>
            </div>
            <h2 style={{ paddingBottom: "1rem" }}>
              This is A rated Movie <br />
              Press Yes to continue
            </h2>
            <Col></Col>
            <Button
              variant="secondary"
              style={{
                color: "white",
                backgroundColor: "#e20788",
              }}
              onClick={() => acceptWarning()}
            >
              Yes
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="secondary"
              style={{
                color: "white",
                backgroundColor: "#7e7e7e",
              }}
              onClick={() => handleClosePop()}
            >
              No
            </Button>
          </Modal.Body>
        </Modal>
        // <AgePop
        //   show={showDialogAge}
        //   handleClose={() => setShowDialogAge(!showDialogAge)}
        //   handleFilter={handleAge}
        // />
      )}
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}

      {isMobile ? (
        <div className="player-container">
          {/* <Image
            style={{
              borderRadius: "16px",
              padding: "10px",
              marginTop: "-10px",
            }}
            src={someData.mobile_image}
            fluid
            onClick={handleClick}
          /> */}
          <div
            style={{
              position: "relative",
              display: "inline-block",
              marginTop: "-10px",
            }}
          >
            <Image
              style={{ borderRadius: "16px", padding: "10px" }}
              src={someData.mobile_image}
              fluid
              // onClick={() =>
              //   handleClick
              // }
            />
            {/* <div className="overlay"></div> */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <button
                className="loader-upcoming"
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundImage: `url(../kannadaflix_play_icon.png)`,
                  backgroundSize: "cover",
                  border: "none",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                }}
                onClick={handleClick}
              ></button>
            </div>
          </div>

          <div className="dynamic-content" style={{ marginTop: "" }}>
            <Row>
              <Col>
                <h1
                  style={{ color: APP_COLORS.APP_DARK_2, paddingLeft: "10px" }}
                >
                  {someData.title}
                </h1>
              </Col>
            </Row>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  borderRadius: "15px",
                  // marginLeft: "10px",
                  fontSize: "12px",
                  color: APP_COLORS.APP_DARK_2,
                  paddingLeft: "10px",
                  // backgroundColor: "#e20788",
                  // width: "60px",
                  // marginRight: "10px", // Adjust margin as needed
                }}
              >
                U/A
              </h6>
              <h6
                style={{
                  display: "inline-block",
                  marginLeft: "10px",
                  fontSize: "12px",
                  color: APP_COLORS.APP_DARK_2,
                }}
              >
                {mediaData && formatGenres(mediaData.geners1)}
              </h6>
            </div>
            <h6
              style={{
                color: APP_COLORS.APP_DARK_2,
                fontSize: "12px",
                paddingLeft: "10px",
                paddingRight: "15px",
                textAlign: "left",
                marginTop: "-4px",
              }}
            >
              {someData.description}
            </h6>
            <Col md={12} className="pb-1" style={{ marginTop: "-10px" }}>
              <Button
                variant="primary"
                style={{
                  height: "40px",
                  width: "auto",
                  backgroundColor: APP_COLORS.APP_DARK_1,
                  borderWidth: "0",
                  marginLeft: "10px",
                }}
                // onClick={() => setCurrentVideoUrl(mediaData.video)}
                onClick={handleClick}
              >
                <BsPlayFill
                  size={20}
                  className="mr-2"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                />
                Play
              </Button>
              <Button
                variant="primary"
                // onClick={handleShow}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                  width: "auto",
                  backgroundColor: APP_COLORS.APP_DARK_1,
                  borderWidth: "0",
                }}
                // onClick={() => setCurrentVideoUrl(mediaData.trailer_video)}
                onClick={handleClickTrailer}
              >
                <BsFillPlayCircleFill
                  size={20}
                  className="mr-2"
                  style={{ marginBottom: "4px", marginRight: "5px" }}
                />
                Watch Trailer
              </Button>
            </Col>

            <h6
              style={{
                color: APP_COLORS.APP_DARK_2,
                padding: "10px",
                marginTop: "-5px",
                fontSize: "12px",
              }}
            >
              Director: {mediaData && mediaData.director}
            </h6>
            <h6
              style={{
                color: APP_COLORS.APP_DARK_2,
                paddingLeft: "10px",
                marginTop: "-15px",
                fontSize: "12px",
              }}
            >
              Cast: {mediaData && getCastNames(mediaData.cast)}
            </h6>
            {/* <Row>
              <Col xs={3}>
                <h5
                  className="title mt-0 mb-0 text-white"
                  style={{ paddingLeft: "10px" }}
                >
                  Director:
                </h5>
              </Col>
              <Col xs={9} className="d-flex align-items-center">
                <p className="text-white">{mediaData && mediaData.director}</p>
              </Col>
              <Col xs={9}>
                <h5
                  className="title mt-0 mb-0 text-white"
                  style={{ paddingLeft: "10px" }}
                >
                  Cast: {getCastNames(mediaData && mediaData.cast)}
                </h5>
              </Col>
              <Col xs={9}>
                <ul className="no-bullet">
                  {mediaData &&
                    mediaData.cast.map((item, idx) => (
                      <li key={idx} className="links-vertical">
                        {item.name}
                      </li>
                    ))}
                </ul>
              </Col> 
            </Row> */}
            {mediaData &&
            mediaData.seasons1 &&
            Array.isArray(mediaData.seasons1[1]) ? (
              <Button
                variant="info"
                style={{
                  marginLeft: "10px",
                  backgroundColor: APP_COLORS.APP_DARK_1,
                  color: "white",
                  borderColor: APP_COLORS.APP_DARK_1,
                  marginTop: "-1px",
                }}
              >
                Season 1
              </Button>
            ) : null}

            {mediaData &&
            mediaData.seasons2 &&
            Array.isArray(mediaData.seasons2[1]) ? (
              <Button
                variant="info"
                style={{
                  marginLeft: "10px",
                  backgroundColor: APP_COLORS.APP_DARK_1,
                  color: "white",
                  borderColor: APP_COLORS.APP_DARK_1,
                  marginTop: "-1px",
                }}
              >
                Season 1
              </Button>
            ) : null}

            {/* <CommonListing items={items} /> */}
            <div className="movie-list">
              {mediaData &&
              mediaData.seasons1 &&
              Array.isArray(mediaData.seasons1[1])
                ? mediaData.seasons1[1].map((item, index) => (
                    <Card
                      key={index}
                      className="movie-item"
                      style={{
                        backgroundColor: "#14141400",
                        position: "relative",
                        paddingBottom: "5rem",
                      }}
                      // onClick={() => setCurrentVideoUrl(item.video)}
                    >
                      <Card.Img variant="top" src={item.mobile_image} />
                      <p
                        style={{
                          fontSize: "small",
                          color: APP_COLORS.APP_DARK_2,
                        }}
                      >
                        {item.ep_title}
                      </p>
                      <Button
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => createVideoData(item)}
                      >
                        <img
                          src="../kannadaflix_play_icon.png"
                          alt="Play Icon"
                          style={{ width: "50px", height: "50px" }} // Set the size of the icon
                        />
                      </Button>
                    </Card>
                  ))
                : null}
            </div>
            <>
              {watchNext && watchNext.length > 0 && (
                <h4
                  style={{
                    color: APP_COLORS.APP_DARK_1,
                    marginBottom: "0",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Watch Next
                </h4>
              )}
              {watchNext && watchNext.length > 0 && (
                <div
                  className="movie-list-next"
                  style={{ paddingBottom: "4rem" }}
                >
                  {watchNext
                    ? watchNext.map((item, index) => (
                        <Card
                          key={index}
                          className="movie-item-next"
                          style={{
                            backgroundColor: "#14141400",
                            position: "relative",
                            paddingBottom: "5rem",
                          }}
                          onClick={() => createVideoData(item)}
                        >
                          <Card.Img
                            variant="top"
                            src={item.mobile_image}
                            style={{
                              objectFit: "cover",
                              width: "50%",
                              paddingRight: "0.7rem",
                              paddingBottom: "0.7rem",
                              borderRadius: "20px",
                              padding: "10px",
                            }}
                          />
                          {/* <p
                      className="text-white"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "10px",
                      }}
                    >
                      {item.title}
                    </p> */}
                        </Card>
                      ))
                    : null}
                </div>
              )}
            </>
          </div>
        </div>
      ) : (
        // <MobilePlayer someData={someData} type={"main"} />
        // <div className="player-container">
        //   {/* <Image
        //     style={{ borderRadius: "8px", padding: "10px" }}
        //     src={someData.mobile_image}
        //     fluid
        //   /> */}
        //   {currentVideoUrl && (
        //     <VideoJsPlayer
        //       src={currentVideoUrl}
        //       poster={someData.mobile_image}
        //       className="react-player"
        //       isMobile={isMobile}
        //     />
        //   )}

        //   <div className="dynamic-content" style={{ marginTop: "60%" }}>
        //     <Row>
        //       <Col>
        //         <h1 style={{ color: "white", paddingLeft: "10px" }}>
        //           {someData.title}
        //         </h1>
        //       </Col>
        //       <Col className="col-md-6"></Col>
        //     </Row>
        //     <div style={{ display: "flex", alignItems: "center" }}>
        //       <h6
        //         style={{
        //           display: "inline-block",
        //           textAlign: "center",
        //           borderRadius: "15px",
        //           marginLeft: "10px",
        //           color: "white",
        //           padding: "10px",
        //           backgroundColor: "#e20788",
        //           width: "60px",
        //           marginRight: "10px", // Adjust margin as needed
        //         }}
        //       >
        //         U/A
        //       </h6>
        //       <h6
        //         style={{
        //           display: "inline-block",
        //           marginLeft: "10px",
        //           color: "white",
        //         }}
        //       >
        //         {mediaData && formatGenres(mediaData.geners1)}
        //       </h6>
        //     </div>
        //     <p
        //       style={{
        //         color: "white",
        //         fontSize: "14px",
        //         paddingLeft: "10px",
        //         paddingRight: "10px",
        //         textAlign: "left",
        //       }}
        //     >
        //       {someData.description}
        //     </p>
        //     <Col md={12} className="pt-3 pb-5">
        //       <Button
        //         variant="primary"
        //         style={{
        //           height: "40px",
        //           width: "auto",
        //           backgroundColor: "#58b7aa",
        //           borderWidth: "0",
        //           marginLeft: "10px",
        //         }}
        //         onClick={() => setCurrentVideoUrl(mediaData.video)}
        //         // onClick={handleClick}
        //       >
        //         <BsPlayFill
        //           size={20}
        //           className="mr-2"
        //           style={{ marginBottom: "3px", marginRight: "5px" }}
        //         />
        //         Play
        //       </Button>
        //       <Button
        //         variant="primary"
        //         // onClick={handleShow}
        //         style={{
        //           marginLeft: "10px",
        //           height: "40px",
        //           width: "auto",
        //           backgroundColor: "#58b7aa",
        //           borderWidth: "0",
        //         }}
        //         onClick={() => setCurrentVideoUrl(mediaData.trailer_video)}
        //         // onClick={handleClickTrailer}
        //       >
        //         <BsFillPlayCircleFill
        //           size={20}
        //           className="mr-2"
        //           style={{ marginBottom: "4px", marginRight: "5px" }}
        //         />
        //         Watch Trailer
        //       </Button>
        //     </Col>
        //     <Row>
        //       <Col xs={3}>
        //         <h5
        //           className="title mt-0 mb-0 text-white"
        //           style={{ paddingLeft: "10px" }}
        //         >
        //           Director:
        //         </h5>
        //       </Col>
        //       <Col xs={9} className="d-flex align-items-center">
        //         <p className="text-white">{mediaData && mediaData.director}</p>
        //       </Col>
        //       <Col xs={3}>
        //         <h5
        //           className="title mt-0 mb-0 text-white"
        //           style={{ paddingLeft: "10px" }}
        //         >
        //           Cast:
        //         </h5>
        //       </Col>
        //       <Col xs={9}>
        //         <ul className="no-bullet">
        //           {mediaData &&
        //             mediaData.cast.map((item, idx) => (
        //               <li key={idx} className="links-vertical">
        //                 {item.name}
        //               </li>
        //             ))}
        //         </ul>
        //       </Col>
        //     </Row>
        //     {mediaData &&
        //     mediaData.seasons1 &&
        //     Array.isArray(mediaData.seasons1[1]) ? (
        //       <Button
        //         variant="info"
        //         style={{
        //           marginLeft: "10px",
        //           backgroundColor: "#1FB9A9",
        //           color: "white",
        //         }}
        //       >
        //         Season 1
        //       </Button>
        //     ) : null}

        //     {mediaData &&
        //     mediaData.seasons2 &&
        //     Array.isArray(mediaData.seasons2[1]) ? (
        //       <Button
        //         variant="info"
        //         style={{
        //           marginLeft: "10px",
        //           backgroundColor: "#1FB9A9",
        //           color: "white",
        //         }}
        //       >
        //         Season 1
        //       </Button>
        //     ) : null}

        //     {/* <CommonListing items={items} /> */}
        //     <div className="movie-list">
        //       {mediaData &&
        //       mediaData.seasons1 &&
        //       Array.isArray(mediaData.seasons1[1])
        //         ? mediaData.seasons1[1].map((item, index) => (
        //             <Card
        //               key={index}
        //               className="movie-item"
        //               style={{
        //                 backgroundColor: "#14141400",
        //                 position: "relative",
        //                 paddingBottom: "5rem",
        //               }}
        //               onClick={() => setCurrentVideoUrl(item.video)}
        //             >
        //               <Card.Img variant="top" src={item.mobile_image} />
        //               {/* <p
        //                 className="text-white"
        //                 style={{
        //                   position: "absolute",
        //                   bottom: "0px",
        //                   right: "10px",
        //                 }}
        //               >
        //                 {item.title}
        //               </p> */}
        //             </Card>
        //           ))
        //         : null}
        //     </div>
        //   </div>
        // </div>
        <>
          {showPlayer ? null : (
            <div
              style={{
                position: "relative",
                backgroundImage: `url(${mediaData && mediaData.mobile_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
              }}
            >
              <div className="overlay"></div>
              <Container className="content-container">
                <Row className="justify-content-center">
                  <Col xs={12} md={10} lg={8}>
                    <Tabs
                      activeKey={activeTab}
                      onSelect={handleTabChange}
                      variant="pills"
                      className="mt-5"
                    >
                      <Tab
                        eventKey="view1"
                        title={
                          <>
                            <BsFillStarFill
                              style={{
                                marginBottom: "4px",
                                marginLeft: "4px",
                              }}
                            />{" "}
                            Overview
                          </>
                        }
                        active={activeTab === "view1"}
                      >
                        <div className="mt-4">
                          <OverView
                            title={mediaData && mediaData.title}
                            desc={mediaData && mediaData.description}
                            director={mediaData && mediaData.director}
                            cast={mediaData && mediaData.cast}
                            genres={mediaData && mediaData.geners1}
                            data={mediaData && mediaData}
                            props={props}
                          />
                        </div>
                      </Tab>
                      {mediaData &&
                      mediaData.seasons1 &&
                      Array.isArray(mediaData.seasons1[1]) ? (
                        <Tab
                          eventKey="view2"
                          title={
                            <>
                              <BsFillCalendarFill /> Seasons & Episodes
                            </>
                          }
                          active={activeTab === "view2"}
                        >
                          <div className="mt-4">
                            <div>
                              <h2 className="text-white">{someData.title}</h2>
                              <Container
                                className="content-container"
                                style={{
                                  paddingTop: "50px",
                                  paddingBottom: "50px",
                                }}
                              >
                                <Tabs
                                  activeKey={seasonsActiveTab}
                                  onSelect={handleTabChange}
                                  style={{}}
                                  variant="pills"
                                >
                                  <Tab eventKey="view1" title="Seasone 1">
                                    <div>
                                      <CommonListing
                                        imageUrls={mediaData.seasons1[1]}
                                        handleOpenVideo={(value) => {
                                          if (isSubscribed) {
                                            playVideo(
                                              value.video,
                                              value.mobile_image
                                            );
                                          } else {
                                            navigate("/subscribe");
                                          }
                                        }}
                                        // handleOpenVideo={
                                        //   (value) =>
                                        //     playVideo(
                                        //       value.video,
                                        //       value.mobile_image
                                        //     )
                                        //   // handleClickSeasons(value)
                                        // }
                                        // onClick={onListingClick}
                                      />
                                    </div>
                                  </Tab>
                                </Tabs>
                              </Container>
                            </div>
                            {/* <Seasons
                              title={someData.title}
                              data={mediaData.seasons1[1]}
                              onListingClick={handleListingClick}
                            /> */}
                          </div>
                        </Tab>
                      ) : null}
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Details;
// import React, { useState } from 'react';
// import './Details.css';
// import { Container, Tab, Tabs, Row, Col  } from 'react-bootstrap';
// import { BsFillStarFill, BsFillCalendarFill } from 'react-icons/bs';
// import OverView from './Details/OverView';
// import Seasons from './Details/Seasons';

// const Details = () => {
//     const [activeTab, setActiveTab] = useState('view1');

//   const containerStyle = {
//     backgroundImage: `url(${'https://media1.olaple.com/images/videos/1638257242title.jpg'})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     /* You can add more styles here as needed */
//   };

//   const overlayStyle = {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     zIndex: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//   };
//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };
//   return (
//     <div className="background-image-container" style={containerStyle}>
//       <div style={overlayStyle}></div> {/* Add overlay */}
//       <Container className="content-container" style={{ position: 'relative', zIndex: 1 }}>
//         <Row className="justify-content-center">
//           <Col xs={12} md={10} lg={8}>
//             <Tabs
//               activeKey={activeTab}
//               onSelect={handleTabChange}
//               variant="pills"
//               className="mt-5"
//             >
//               <Tab
//                 eventKey="view1"
//                 title={<><BsFillStarFill /> Overview</>}
//                 active={activeTab === 'view1'}
//               >
//                 <div className="mt-4">
//                   <OverView
//                     title="Raja Ki Rani"
//                     director="Azaad Bharti"
//                     cast={['Rani Chatterjee', 'Rajesh Sharma', 'Abhishek Bajaj']}
//                     genres={['Drama', 'Mystery', 'Thriller']}
//                   />
//                 </div>
//               </Tab>
//               <Tab
//                 eventKey="view2"
//                 title={<><BsFillCalendarFill /> Seasons & Episodes</>}
//                 active={activeTab === 'view2'}
//               >
//                 <div className="mt-4">
//                   <Seasons
//                     title="Seasons"
//                     director="Azaad Bharti"
//                     cast={['Rani Chatterjee', 'Rajesh Sharma', 'Abhishek Bajaj']}
//                     genres={['Drama', 'Mystery', 'Thriller']}
//                   />
//                 </div>
//               </Tab>
//             </Tabs>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default Details;
