import React, { useEffect, useState } from "react";
import "./Upcoming.css"; // Import custom CSS for styling
import { Link, useNavigate } from "react-router-dom";
import instance, { APP_COLORS, APP_IMAGES } from "./common/Api";
import Spinner from "react-bootstrap/Spinner";
import { useUser } from "../UserContext";
import Loader from "./common/Loader";
const Upcoming = (props) => {
  // Sample data for containers
  const [upcomingData, setUpcomingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser();
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userIP, setUserIP] = useState(null);

  const someData = "your data here";

  useEffect(() => {
    instance
      .post("/upcoming_videos")
      .then((response) => {
        setUpcomingData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });
    checkSubscribtion();
  }, []);

  const checkSubscribtion = () => {
    // console.log(props.userData, "URR");
    const fetchUserIP = async () => {
      try {
        // Update IP using your method to get the user's IP
        const ip = await window.getUserIP();
        console.log("Fetched IP:", ip);

        // Assuming 'Unknown' means IP could not be determined
        if (ip !== "Unknown") {
          setUserIP(ip); // Set userIP state only if it's not 'Unknown'
        }
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };
    fetchUserIP().then(() => {
      if (props.userData != null) {
        instance
          .post(`/subscription_plans_web?id=${props.userData.id}&ip=${userIP}`)
          .then((response) => {
            //console.log("response.data", response.data);
            const active = response.data.active_plan;
            console.log(active[0], "<active");
            if (response.data && response.data.active_plan.length > 0) {
              // setActivePlans(response.data.active_plan[0]);
              setIsSubscribed(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false when fetch is complete
          });
        //console.log("User ID:", props.userData.id);
      } else {
        //console.log("User ID not found");
        setLoading(false);
      }
    });
  };

  const handleClick = (item) => {
    // if (props.isMobile) {
    //   navigate("/play", { state: { someData: item } });
    // } else {
    //   navigate("/media/", { state: { someData: item } });
    // }

    if (props.userData != null) {
      if (isSubscribed) {
        // navigate("/play", { state: { someData: item } });
        if (props.isMobile) {
          // if (props.userData.age && props.userData.age === "18+") {
          //   setShowDialogAge(true);
          // } else {
          navigate("/play", {
            state: { someData: item },
          });
          // }
        } else {
          // if (props.userData.age && props.userData.age === "18+") {
          //   setShowDialogAge(true);
          // } else {
          navigate("/play", {
            state: { src: item.trailer_video, poster: item.mobile_image },
          });
          // }
        }
      } else {
        // navigate("/media/", { state: { someData: item } });
        navigate("/subscribe");
      }
    } else {
      navigate("/login/");
    }
  };

  return (
    <div
      style={
        props.isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              // position: "relative",
              backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_MOB})`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
          : {
              minHeight: "55rem",
              backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
      }
    >
      <div className="container pb-4 pt-2">
        {loading && (
          <div className="loading-overlay">
            <Loader isLoading={loading} />
          </div>
        )}
        <div
          className="container-list"
          style={{ marginTop: props.isMobile ? "-5px" : "" }}
        >
          {upcomingData &&
            upcomingData.map((item, index) => (
              <div key={index} className="position-relative">
                <section className="min upcoming">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <div
                          className="image-container position-relative"
                          onClick={() =>
                            item.image_status == 0 ? handleClick(item) : ""
                          }
                        >
                          <img
                            src={item.default_image}
                            alt={item.title}
                            className="container-image"
                          />
                          {/* <div className=".overlay-upcoming">
                    <BsFillCaretRightFill className=".play-icon" /> </div>  */}
                          {item.image_status == 0 &&
                            (props.isMobile ? (
                              <div className="overlay-upcoming">
                                <img
                                  className="loader-upcoming"
                                  src="./kannadaflix_play_icon.png"
                                  alt="Play Icon"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-40%, -40%)",
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="overlay-upcoming">
                                <img
                                  className="loader-upcoming"
                                  src={"./play-button.jpg"}
                                  alt={"./play-button.jpg"}
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-40%, -40%)",
                                  }}
                                />
                              </div>
                            ))}
                          {/* </Link> */}
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                  <div
                    className="heading1"
                    style={{ color: "#ffffff", textAlign: "justify" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <h3
                            className="custom-h3"
                            style={{ color: APP_COLORS.APP_DARK_1 }}
                          >
                            {item.title}
                          </h3>
                          {/* <h4 className="custom-h4">{item.director}</h4> */}
                          <p
                            className="custom-p"
                            style={{
                              color: APP_COLORS.APP_DARK_2,
                              fontWeight: "600",
                            }}
                          >
                            {item.description}
                          </p>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Upcoming;
