import { APP_COLORS, APP_IMAGES } from "../common/Api";
import "./Cookies.css";
import React from "react";

const TermsConditions = () => {
  const titles = [
    {
      title: "INTRODUCTION",
      subtitles: [
        "This User Terms and Conditions, as amended up to date, (this “Agreement”) comprises terms of browsing, use, access or availing services of https://www.KannadaFlix.com including related sub-domains, hyperlinks, pages, and tools (“Website”); and processing of Your data /information /personal data through the Website.",
        "Niovi Digital Ltd., is a company having its registered office at 10 Barnetts Court,Harrow,HA2 8EU (the “Company”) is the owner and operator of the Website. These Terms and Conditions constitutes a legal binding Agreement between the Company and You pertaining to Your use, access, or availing services and sharing Your data through the Website https://www.KannadaFlix.com",
        "If You have any question, comment and /or request regarding this Agreement, You may please contact Us at help@KannadaFlix.com",
      ],
    },
    {
      title: "APPLICABILITY",
      subtitles: [
        "All ‘Services’ provided by Us through the ‘Website’ will be governed by this Agreement.",
        "This Agreement is applicable to the Visitor(s) who access or browse the Website, in any manner (‘Visitors’) and those who register or log in the Website (‘Subscribers’).",
        "The words You or Your or User as used herein, refer to the Visitor(s) as well as the Subscriber(s),",
      ],
    },
    {
      title: "YOUR ACKNOWLEDGMENT",
      subtitles: [
        "By browsing, using, accessing, or availing the services of the Website, in any manner, You represent, agree, acknowledge, and consent that",
        "You are eighteen (18) years of age or You have attained the age of majority as per the laws of Your country / state / province / residence /jurisdiction (We don’t knowingly seek to browse, use, access Website or avail Our Services or collect any Personal information from the persons who have not attained the age of majority). We specifically disclaim any responsibility or liability for any misrepresentation regarding the User’s age; and",
        "You have thoroughly read, understood, and agreed to be bound by terms this Agreement; and",
        "This Agreement is binding and enforceable against You; and",
        "Execution of this Agreement by You do not conflict with any of the Yours obligation under any Applicable Laws to the best of Your knowledge; and",
        "You have read, understood and agreed to abide by the Privacy Policy, which is available at the link https://www.KannadaFlix.com/privacy-policy (the “Privacy Policy”) and the Refund Policy at the link https://KannadaFlix.com/#/refund-policy (the “Refund Policy”). Both are incorporated herein by reference; and",
        "To transfer of Your data or information to Us, and processing of such data by Us; and",
        "The laws governing Your individual city, country, state, province or nation may regulate the activity discussed or promoted by the Website or by the third parties communicating on or through Website. Therefore, You are required to check Your local laws before taking part of Your activity on the website. And, You acknowledge that that You have due knowledge and checked that Your city, country, state, provide or nation don’t prohibit You from browsing, using, accessing or otherwise availing the services of Website. You agree not to browse, use, access or avail services of the Website, if doing so would violate the laws of Your state, province or country You reside; and",
        "You will only access the content on the Website and /use or avail it’s Services if You believe that the contents of the Website do not offend the community standards prevalent in Your community.",
        "Persons who don’t meet above criteria are strictly forbidden from browsing, using, accessing, availing it’s services or viewing the contents of the Website.",
        "If You don’t agree to these Terms & Conditions/ the terms of this Agreement, You should not register or avail Our ‘Services’",
      ],
    },
    {
      title: "CONSENT TO THIS AGREEMENTM",
      subtitles: [
        "You are not authorised to browse, use, access or avail our services, in any manner, unless You agree to the terms of this Agreement. You are required to provide agreement, consent or acceptance of this Agreement. Such acceptance of this Agreement need not to be a physical signature since electronic acceptance of this Agreement is permitted by the law. Thus, You agree that You manifest Your consent to this Agreement by any of Your following acts demonstrating Your assent thereto",
        ,
        "By clicking a button containing the words “I agree” or some similar syntax. You should understand that this has same legal effect as You placing Your physical signature on any other legal contract; or",
        "If You click on any link, button, or other device provided to You on any part of Website interface, You have legally agreed to the terms of this Agreement; or",
        "By browsing, using, accessing the Website (or any page) or availing Our services in any manner, including uploading Your information or content on the Website, You understand and agree that You provide Your complete and unconditional acceptance of all the terms of this Agreement",
        "No act or omission by Us should be interpreted as a waiver of the requirement that You assent to this Agreement",
      ],
    },
    {
      title: "REVISION /MODIFICATION OF THIS AGREEMENT",
      subtitles: [
        "We have reserved our right to revise or modify this Agreement from time to time without any prior notice or intimation to You. All such modification or revision shall immediately be effective as and when posted on the Website. Once modification or revision is posted, the prior version shall be superseded by the modified or revised version. The modified or revised version shall be immediately applicable to all Users and for that no further assent or consent shall be required from the User, and all Users shall be bind by such modified or revised version. To the extent any such modification or revision is declared or deemed ineffective by any law or court of law, the parties intend that prior or effective version of this Agreement shall be considered valid and enforceable to the fullest extent.",
        "This Agreement shall be located at the link https://KannadaFlix.com/#/terms-and-conditions and a link to this Agreement is also at the bottom of the home page of the Website. You agree to periodically re-visit this page and to use the “refresh” button on Your browser when doing so.",
        "Whenever We modify agreement, We will change the “last modified” date at the top of the Agreement so that it is immediately obvious that We have modified the Agreement. You agree to note the date of the last revision whenever You visit the link https://KannadaFlix.com/#/terms-and-conditions If the last modified date has changed, then You are required to thoroughly go through the terms of this Agreement to determine how Your rights and responsibilities may have affected by the modification.",
        "If You fail to periodically review this Agreement to determine if any changes or modifications have made, You assume all responsibility of Your failure to do so and You agree that such failure amounts to Your affirmative waiver of Your right to review the modified or revised terms. We will not be responsible for Your neglect of Your legal rights.",
      ],
    },
    {
      title: "INCORPORATION BY REFERANCE",
      subtitles: [
        "The Privacy Policy, Refund Policy and all the additional guidelines, rules, notifications, which can be find on this Website and which has been specifically incorporated by reference shall form part and parcel of this Agreement.",
      ],
    },
    {
      title: "DEFINITIONS",
      subtitles: [
        "Following terms or words as appearing in this Agreement, unless repugnant to the context and meaning thereof, shall have the meaning ascribed to them",
        "“Agreement” or “this Agreement”: means or denote this Agreement (as amended up to date) and includes Privacy Policy, Refund Policy, and other guidelines, rules, notification etc. as may appear on the Website from time to time.",
        "“Company”: denotes “Niovi Digital Ltd., is a company having its registered office at 10 Barnetts Court,Harrow,HA2 8EU (the “Company”) which is the owner and operator of www.KannadaFlix.com. Company has also been referred in this Agreement as “We”, “Us”, “Our”, “Ours” etc.",
        "“Information”: includes ‘Personal Information or Personal Data.",
        "“Member” denotes the Subscriber.",
        "“Parties” shall denote jointly the Company and You.",
        "“Personal data” or “Data” includes ‘Personal Information” or “Personal Data.",
        "“Personal Information/Data”: shall mean any document/information You provide to Us and shall include, without limitation, ‘Your name’, ‘phone no’, ‘Your address’, ‘date of birth’, ‘email address’, ‘gender’, ‘photograph’, ‘occupation’, or credit card or debit card or net banking, other payments details, Your identity proof document number, addressee proof document no, details from data base, social media activity, log in id, password and similar other information.",
        "“Processing”: whenever referred means handling of Your Personal Data by Us, including collecting, protecting, and storing Your Personal Data.",
        "“Services”: of the Website may contain images, content including but not limited to text, audio -video content, software, image, graphics, data, message or any other information, website content owned, operated, licensed or controlled by the Company.",
        "“Subscriber” denotes person who registers himself /herself by creating an user account.",
        "“You” or “User”: denotes Visitors (who browse, use or access website without registering or becoming subscribers) and the Subscribers (who register themselves with the Website as member). You further certified that You have attained the age of majority.",
        "The Terms and Conditions herein in this Agreement shall apply equally to both the singular and plural form of the terms defined. Whenever the context may require, any pronoun shall include the corresponding masculine and feminine.",
        "The headings and subheadings herein are used for convenience only and are not intended to describe, interpret, define or limit the scope, extent or intent of this Terms and Conditions.",
      ],
    },
    {
      title: "OPTIONAL TOOLS",
      subtitles: [
        "The User may access certain area without requirement of registration. However, to avail full services of the Website You are required to became member by subscribing the services of the Website. The use, access or availing the services of Our Website shall be at Your sole responsibility",
        "The Website may come with various Subscription plans, and You may subscribe Subscription plan of Your choice. The access to the Website may be limited according to such Subscription plans. Such Subscription plans may be revised, updated, modified, changed, or any of the subscription plan may be suspended, stopped, ceased by the Company, at its sole discretion.",
        "By accessing the Website, You certify that:",
        "You are using the Website solely for personal and non-commercial purposes; and",
        "You will not copy or distribute any part of the Website without our prior written authorization; and",
        "You will fully comply with the terms of this Agreement",
        "The access to the Website, in any manner (whether registration or without registration) is subject to the term of this Agreement. Further, rights granted to the User hereunder is limited, revocable, non-exclusive, non-transferable and non-assignable and is granted to access, view and otherwise use the Website (including, without limitation any information or services provided on or through the Website) for personal, lawful use only.",
      ],
    },
    {
      title: "SUBCRIPTION / REGISTRATION",
      subtitles: [
        "In order to avail full services of the Website You need to subscribe Our services by creating an User Account. You must register as a member of the Website including by creating user account with password, providing certain required information, and payment of the subscription fee.",
        "We, at our sole discretion, reserves the right to refuse registration of, or cancel or restrict the usage of the Website for any User.",
        "Please note that You, at Your own cost, (a) need to have provision for internet access, and (b) provision for hardware and software to access the Website or its services. Payment of subscription fee is only to avail the Website’s services.",
        "Users are solely responsible for any activity that occurs on their Account and for maintaining the confidentiality of their Account. Users are prohibited from: (i) using another User’s account; (ii) impersonate another User; (iii) create a log in name for any Account that is offensive, vulgar or obscene or otherwise unlawful. If any of the above-mentioned activities take place, the User shall immediately notify Us",
      ],
    },
    {
      title: "SUBSCRIPTION FEE",
      subtitles: [
        "To fully avail the services of the Website You need to register by payment of the subscription fee. The subscription fee is liable to revision from time to time, at the sole discretion of the Company and without any prior notice or information",
        "We may, at our sole discretion, introduce new services and modify some or all of the existing services offered. In such an event, We reserve Our right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be.",
        "Changes to the fee policies shall be posted on the Website and shall automatically become effective immediately. Unless otherwise stated, all fees shall be quoted in USD and be payable to Company at the time of subscription or renewal of subscription. All the fee mentioned may not include any applicable taxes which will be charged in addition to fee. In case of any non-payment, We have right to temporarily/indefinitely suspend the User’s membership of the Website and reserves the right to take legal action.",
        "The User can pay the Company through certain gateway services available on the Website or other modes prescribed (which may include payment through Debit/Credit cards, Net banking, Wallets etc).",
        "The Payment methods may differ from platform to platform (Operating systems). In the event Your subscription period expires, We reserve our right to auto-renew Your subscription through Your authorised payment method, provided such payment method offers the service of auto-renewal. You hereby authorise Us to effect such auto-renewal. In event You desire to discontinue Your auto-renewed subscription, You may contact us at help@KannadaFlix.com.",
        "All fees and charges are non-refundable and there is no refund or credits for partially -fully used periods and /or unwatched content.",
        "You agree to provide current, complete and accurate account information for all subscriptions",
        "You agree to promptly update Your account and other information, including Your email address and payment information, so that We can complete Your transactions and contact You when needed.",
      ],
    },
    {
      title: "WEBSITE’S CONTENTS",
      subtitles: [
        "The Website shall contain images, and contents including but not limited to the text, audio, video, images, graphics, data, messages, software or any other contents, and information owned, operated, licensed or controlled by the Company (the “Materials”). Some or all the Materials on the Website or transmitted via Website depict activities that are restricted to adults (who has attained the age of majority) and therefore are inappropriate to the minors",
        "You acknowledge, understand, assent that:",
        "You are aware of the nature of the Materials provided by or through the Website and that You are not offended by such Materials.",
        "Your access to the Website and its Services are voluntarily, willingly and for Your own personal entertainment.",
        "All the Materials available via Website or its services whether posted publicly or transmitted through our messaging services or messaging services of the Third Parties, are the sole responsibilities of the Party from whom the contents originated",
        "You are entirely responsible for the contents You upload, post, transmit, email, message or otherwise publish via our Website or Services",
        "You may be exposed to the content that You might find offensive, indecent, problematic, or otherwise objectionable. Under no circumstances We accept liability in any way for the content posted by or uploaded by the User or third party",
        "We reserve Our right, at Our sole discretion and with no obligation to do so, to periodically monitor, member’s profile and forums within the Website and delete the User’s content, pictures, message, forum post or profile that are deemed, at our sole discretion to be illegal, immoral, offensive and in violation of the spirit of this Agreement",
        "It is Your responsibility to prevent minor from accessing the contents of the Website. You agree not to allow minor to view any such content /Materials and You take responsibility to measure to prevent from doing so. And, for this You agree to install appropriate online safety filters. You can file online safety filters by researching such services online. We recommend You to conduct appropriate due diligence before purchasing or installing online safety filters.",
        "You agree to take steps to prevent minor from viewing Our website or content via Our services.",
        "If You are a Parent or guardian of a minor child, it is Your responsibility, not ours, to keep content of Our Website or services from being displayed or accessed by Your minor children.",
      ],
    },
    {
      title: "RESTRICTIONS",
      subtitles: [
        "You agree that:",
        "You will not to use the Website for any illegal purpose. Your use or access to the Website or its services are for the purpose expressly permitted or contemplated under this Agreement.",
        "You will not to copy, distribute, transmit, display, perform, reproduce, publish, License, reverse engineer, create derivative works from, transfer, or sell any information, software, products, services or intellectual property obtained from the Website in any manner whatsoever.",
        "You further agree that You will not:",
        "create, post publish or submit anything (including image, photographs or other Materials) that is unlawful, pornographic, defamatory, libellous, obscene, threatening, harassing, discriminatory, bullying, vulgar, indecent, profane, hateful, racially, culturally or ethnically offensive or that encourages criminal conduct, or that gives rise, or potentially gives rise, to civil or criminal liability, or that violates any Applicable Laws, rules or that infringes or violates other parties' intellectual property rights or links to infringing or unauthorized content;",
        "interfere with or disrupt, or attempt to interfere with or disrupt, the operation of the Website;",
        "violate any copyright notices, information, restrictions contained in or associated with the Website;",
        "remove, alter, interfere with or circumvent any copyright, trademark, watermark, or other proprietary notices marked/displayed on the Website",
        "do anything on the Website that would prevent other users’ access to or use of the Website,",
        "frame or utilise any framing techniques in connection with Our Website or any of the Materials contained therein,",
        "use Our Website or services or any Materials contained therein, for an public display, public performance, sale or rental,",
        "create any derivative works based on our Website or any of the Materials contained therein or received by our services",
        "duplicate any part of Our site or the Materials contained therein or received via the services",
        "collect or store personal data about other users or members including via the use of any data mining, bots or similar gathering and extraction tools",
        "stalk or otherwise harass any other member or User of the Website",
        "intentionally or unintentionally violate any applicable local, state, national or international law and any regulation having force of law,",
        "interfere with or disrupt the Website, or servers or networks connected to the Website, or disobey any requirements, procedures, policies, regulations of networks or third party providers connected to the Website,",
        "impersonate any person or entity or falsely state or otherwise misrepresent Your affiliation to any person or entity.",
        "circumvent any encryption or other security tools of the Website or its services",
        "will not use any other user’s name and passwords in order to gain access to the Website",
        "sell, rent, lease, license, sublicense, distribute, re-transmit, transfer, assign the Materials or services or any of Your rights under this Agreement.",
        "share other Member’s information unless express consent is given by such Member to You to do so.",
        "upload files that contains viruses, Trojan horses, worms , time bombs, cancelbots, corrupted files or any other similar software or programs that may damage the operation of the Website or its services.",
        "falsify or delete any author attributions, legal or other proper notices, or proprietary designations or labels of origin, or source of software or other material contained in the file that is uploaded.",
        "restrict or inhibit any other user form using and enjoying Our services.",
        "harvest or otherwise collect information about others, including email addresses, or other personally identifiable information.",
        "create a false identity, including misrepresented real age, location, country of residence, country of origin, religion, height, weight, and any other item of personal description for the purpose of misleading Us or others.",
      ],
    },
    {
      title: "USER’S FURTHER COVENANT",
      subtitles: [
        "The User hereby grants the Company a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, and database rights that the User has in the Information provided by such User to the Company. The Company shall use such information in accordance with the Privacy Policy available at https://www.KannadaFlix.com/privacy-policy.",
        "We will have the right, but not the obligation, to monitor the content of the Website at all times, including any chat rooms and forums that may be included as part of the Website, to determine compliance with Applicable Laws. Without limiting the foregoing, We will have the right to: (i) remove, suspend, edit or modify any content in its sole discretion without notice to the User; (ii) to remove, suspend or block any User submissions from the Website; and (c) access, read, preserve, and disclose any information as required under Applicable Law",
        "All trademarks, patents, copyrights and other intellectual property rights owned by the Company shall continue to be owned by it, and, nothing in this Agreement shall be deemed to confer any rights to any such intellectual property on the User",
        "The User will cooperate with Us in causing any unauthorised use to cease immediately. You are solely responsible for submitting any material that violates the national and international laws, even if a claim arises after Your service is terminated and by doing so, Your action shall constitute a material breach of this Agreement. Nothing contained herein this Agreement shall obligate Us to monitor or investigate any use of Our services by Our Users or other third parties, other than what required under the applicable law.",
        "If You willingly provide wrong information to the Website, such an act would constitute misrepresentation, fraud and cheating.",
        "If You use the Website, You are responsible for maintaining the confidentiality of Your account and password, and for restricting access to Your computer. You agree to accept responsibility for all activities that occur under Your account or password. Because of this, We strongly recommend that You exit from Your account at the end of each session. You agree to notify Us immediately of any unauthorized use of Your account or any other breach of security.",
        "We reserve Our right to delete any content or link without any notice or warning to the User.",
      ],
    },
    {
      title: "BREACH / TERMINATION",
      subtitles: [
        "Reserving other available remedies with Us, We may limit Your activity, and / or immediately remove Your Information, and / or warn other Users of Your actions, and / or immediately temporarily/indefinitely suspend or terminate or block Your membership, and/or refuse to provide You with access to the Website or services in the event",
        "We believe, in Our sole discretion that You have breached any of the terms of this User Agreement, Privacy Policy, Refund Policy and / or other guidelines, rules, regulation or notification as may be notified by the Website; or",
        "If We are unable to verify or authenticate any information You provided; or",
        "If We believe that You have breached any applicable law ; or",
        "If You acted in any manner which is prejudicial or harmful to the interest of the Company/ Website or other Users ; or",
        "If We believe, in our sole discretion, that Your actions may cause legal liability for You, Our users and Us ; or",
        "We decide to cease operations or otherwise discontinue any services or option provided on the Website or parts thereof.",
        "You agree that neither Us nor any third party acting on Our behalf shall be liable to You for any termination of Your account or access to any part of the Website or Services.",
        "A User that has been suspended or blocked may not register or attempt to register with Us or use the Website in any manner whatsoever until such time that such User is reinstated by Us. Till the time the account of User is reinstated, all rights of the User under this Agreement shall be suspended.",
        "In order to maintain the integrity of the Website and services, or to investigate the complaints, You agree to allow Us to access Your account and other information You have submitted or created as long as it is reasonably required for investigation of the complaint or protect the services.",
        "You agree that You will not use Our services to publicly discuss any infractions, warnings, or banning. You must discuss any concerns with Us directly",
        "If You provide any information that is untrue, inaccurate, nor current or incomplete or if We have reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, We have right to suspend or terminate Your account and refuse any current or future use of the Website and its services.",
        "Once the User’s registration or the services are terminated, cancelled or suspended, any data that the User has stored on the Website may not be retrieved later.",
        "Any fee paid hereunder is non-refundable.",
        "You may cancel your subscription /membership at any time by Connecting Support. Not with standing deletion of Your account by You, the relevant and applicable provisions of this Agreement will survive. Upon Our processing Your request of deletion You will no longer be able to access the non-public areas of the Website to which You are a member. Upon deletion or termination, Your data shall be dealt as per the provisions of Privacy Policy",
        "Any User accessing the Website in an effort to engage in or facilitate illegal or tortious activities shall have his /her account and /or access to the Website and services subject to immediate cancellation, and may be reported to appropriate law enforcement agency",
      ],
    },
    {
      title: "LEGAL REMEDY IN CASE OF BREACH",
      subtitles: [
        "In case of breach, We will be entitled to sue You and to claim damages and if Your act is criminal, to start criminal or other proceedings against You, particularly",
        "This Website is not meant for business entity or commercial concern or for commercial use. All Materials and services available on the Website are for individual private use only. We reserve Our right to pursue vigorous legal action against unauthorised log in by business or commercial entities",
        "We reserve the right to initiate legal proceedings against User for fraudulent use of the Website and any other unlawful acts or omissions in breach of the terms of this Agreement.",
        "We take credit/debit card or internet/cyber fraud seriously. Discovery that any member has used a stolen or fraudulent credit card, will result in the notification of the appropriate law enforcement agencies and termination of such Member’s account",
        "You will be subject civil as well as criminal liability, If You provide any information that is untrue, inaccurate, nor current or incomplete or if We have reasonable grounds to suspect that such information is untrue, in accurate, not current, or incomplete.",
        "The term “Damages” includes the cost of litigation, attorney fee and other cost and expenses",
      ],
    },
    {
      title: "LIQUIDATED DAMAGES",
      subtitles: [
        "Without prejudice to Our right to other remedies or damages, You are liable to pay the Company following liquidated damages",
        "If You are infringing Intellectual Property Rights of the Company, in any manner, You will be liable to pay the Company USD 5000 (each instance) exclusive of fees, cost, expenses incurred for recovering this damage, attorney’s fee, litigation cost, and all associated cost and damages.",
        "If You translate, reverse-engineer, decompile, disassemble or make derivative work from Our Material or any other Materials of our Website, You will be liable to pay the Company USD 5000 (each instance) exclusive of fees, cost, expenses incurred for recovering this damage, attorney’s fee, litigation cost, and all associated cost and damages",
        "If You use any automatic device, or manual process to monitor or reproduce the Website or Materials and /or use any device, software, computer code, or Virus to interfere or attempt to disrupt or damage the Website or any communications on it, You will be liable to pay the Company USD 5000 (each instance) exclusive of fees, cost, expenses incurred for recovering this damage, attorney’s fee, litigation cost, and all associated cost and damages",
        "For any breach of a portion of this Agreement that does not specifically state the amount of liquidated damages, You hereby agree that any breach of this Agreement shall result in liquidated damages of USD 500 per occurrence, exclusive of fees, cost, expenses incurred for recovering this damage, attorney’s fee, litigation cost, and all associated cost and damages.",
        "You acknowledge that the amount of liquidated damages is not a penalty. The actual damages may be uncertain and difficult to ascertain, thus the aforesaid amount represents parties good faith attempt to calculate an appropriate compensation based upon anticipated actual damages",
      ],
    },
    {
      title: "DISCLAIMER",
      subtitles: [
        "Notwithstanding anything contrary contained in this Agreement or can be inferred from the terms of this Agreement",
        "The Company /Website disclaim any or all liability arising from the fraudulent entry and use of the Website or any personal or financial information of the User stored in the Website.",
        "The Company /Website shall not be liable for any delay in the services on the Website, including but not limited to the provision of or failure to provide the services, or for any information, software, products, services and related graphics obtained from the Website.",
        "The Company /Website shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the Website that may occur due to technical reasons or for any other reason whatsoever",
        "You agree that any content or data downloaded or otherwise obtained from the Website is done entirely at Your risk and You will be solely responsible for any damage to Your equipment including a phone, internet access, etc., or any other loss that results from downloading such data or content.",
        "The website, service and content are provided 'as is', 'as available' and are provided without any representations or warranties of any kind, express or implied, including, but not limited to, the implied warranties of title, non-infringement, merchantability and fitness for a particular purpose, and any warranties implied by any course of performance or usage of trade, all of which are expressly disclaimed, save to the extent required by law.",
        "Your use of the website, service, content and add-to link is solely at Your sole risk. We further disclaim all warranties, express or implied in connection with the use of the Website in respect of:",
        "Personal injury resulting from the access and use of the Website,",
        "Transmission from our Website any bugs, viruses, trojan horses, or the like which may be transmitted to or through our Website by any Third Party",
        "You understand that The Company /Website or its affiliates have no obligations to verify any information submitted by a User and have assumed that the same are true, correct and complete in all respects.",
        "The Website or its Service may contain, or direct You to websites containing, information that some people may find offensive or inappropriate. The Company /Website makes no representations concerning any content contained in or accessed through the Service, and the Company /Website will not be responsible or liable for the accuracy, compliance, legality or decency of material contained in or accessed through the Website or its Service",
        "The Company /Website makes no warranty that: (a) the Website will meet Your requirements; (b) the Website will be available on an uninterrupted, timely, secure, or error-free basis",
        "The Company /Website or its affiliates make no representation or warranty, express or implied, with respect to any third party data provided to Us or its transmission, timeliness, accuracy or completeness, including but not limited to implied warranties or warranties of merchantability or fitness for a particular purpose. The Company /Website will not be liable in any way to You or to any other person for any inaccuracy, error or delay in or omission of any third party data or the transmission or delivery of any such third party data and any loss or damage arising from (a) any such inaccuracy, error, delay or omission, (b) non-performance or (c) interruption in any such third party data due either to any negligent act or omission by Us or 'force majeure' or any other cause beyond Our control",
        "The Company and its affiliates, shareholders, directors, employees, agents, representatives, suppliers, partners and content providers do not warrant that: (a) the service will be secure or available at any particular time or location; (b) any defects or errors will be corrected; (c) any content or software available at or through the service is free of viruses or other harmful components; or (d) the results of using the service will meet Your requirements.",
        "You hereby discharge, acquit and otherwise release the Company (including its affiliates, subsidiaries, agents, employees, officers, directors, shareholders, attorneys etc.) from all the allegation, counts, charges, debts, cause of action, and claims relating in any way to the use of, or activities relating to the use of the Website and its services.",
      ],
    },
    ,
    {
      title: "LIMITATION OF LIABILITY",
      subtitles: [
        "The Company /Website (and its affiliates, group companies, subsidiaries, respective officers, employees, directors, shareholders, agents, or licensors) shall not be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from: (i) the use or the inability to use the Website or its services; (ii) the cost of procurement of the services; (iii) unauthorized access to or alteration of the Visitor/User’s transmissions or data; or (iv) any other matter relating to the services including damages for loss of use, data or profits, arising out of or in any way connected with availing of the services through the Website",
        "The Company /Website (and its affiliates, group companies, subsidiaries, respective officers, employees, directors, shareholders, agents, or licensors) shall not be responsible for any act or omission which could be attributable to a default of any other person over which We do not exercise control, including without limitation any error or failure or inability of the network service provider",
        "The Company /Website (and its affiliates, group companies, subsidiaries, respective officers, employees, directors, shareholders, agents, or licensors) liability in any circumstance is limited to the amount of fees, if any, paid by You to Company",
      ],
    },
    {
      title: "COMMUNICATION & OPT OUT",
      subtitles: [
        "We reserve Our right to send electronic mail or other messages to You and other members. You understand and agree that the emails sent by Us or Our affiliates are not SPAM. The purpose of the communication with You may include (but not limited to):",
        "Provide You information regarding our product or services.",
        "Inform You of any change or status of Your Account;",
        "Any other Information",
        "If You are no longer interested in receiving e-mail announcements and other marketing information from Us, You can opt-out anytime by sending an e-mail about Your request to help@KannadaFlix.com",
      ],
    },
    ,
    {
      title: "THIRD PARTY LINKS /ADVERTISING",
      subtitles: [
        "The Website may contains links to other Websites. The availability of such third party websites, services or material does not constitute any form of recommendation, advice, endorsement or publication of any such third party websites, services or material. We are not responsible for their availability or content. We are not responsible for the Materials contained at any website linked to Our Website",
        "You acknowledge and agree that We will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance of any such third party content, goods or services available on or through any such website or resources. If You decide to access any such third party website, You do so entirely at Your own risk and subject to any terms and conditions and Privacy Policy posted therein, and not by this Agreement or Our Privacy Policy.",
      ],
    },
    {
      title: "INDEMNITY",
      subtitles: [
        "You agree to indemnify, defend and hold harmless the Company (and its Parent, subsidiary, affiliates, group companies and their directors, officers, employees, agents, third party service providers, and any other third party providing any service to Us in relation to the services provided on the Website) from and against all claims, damages, obligations, losses, liabilities, costs, debt, and expenses (including litigation cost and attorney’s fee) arising from or relate to:",
        "Your use or misuse of or access to the Website or Service",
        "violation of the terms of this Agreement or Privacy Policy,",
        "infringement by You, or any third party using the Your account, of any intellectual property or other right of the Company",
        "any claim that Your content caused damages to a Third Party",
        "This defence and indemnification obligation will survive the termination of this Agreement and Your use of the Website",
        "The Website and its services contains Materials that may be offensive to any third party or not suitable to minors. You agree to indemnify and hold Company (and its Parent, subsidiary, affiliates, group companies and their directors, officers, employees, agents, third party service providers, and any other third party providing any service to Us in relation to the services provided on the Website) harmless from any liability that may arise from someone third party viewing such material.",
        "We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by You, in which event You will assist and cooperate with Us in asserting any available defenses.",
      ],
    },
    ,
    {
      title: "OWNERSHIP /INTELLECTUAL PROPERTY RIGHTS",
      subtitles: [
        "Except for the Content submitted by the Users, any material, content or logos, marks, software on or part of the Service and all aspects thereof, including all copyrights and other intellectual property or proprietary rights therein, is owned by the Company or its licensors. You acknowledge that the Website and any underlying technology or software on the Website or used in connection with rendering the Services are proprietary information owned or duly licensed to the Company, except where it is indicated otherwise. You are prohibited to modify, reproduce, distribute, create derivative works of, publicly display or in any way exploit, any of the content, software, marks, logos, and/or Materials available on the Website in whole or in part except as expressly allowed under the Terms of Use. You have no other express or implied rights to use, in any manner whatsoever, the content, software, marks, logos, and/or Materials available on the Website.",
        "Except as expressly set forth herein, nothing in this Agreement shall be deemed to confer any rights to Company’s intellectual property on the User. For purposes of clarity: Company is the sole owner of the name 'KannadaFlix.com' as well as the Website, and all source code, object code, software, content, copyrights, trademarks, patents and other intellectual property related thereto or included therein. All suggestions, recommendations, bug-fixes, error-fixes or other communications from You to company regarding the Website shall, upon submission to Company, be owned solely and exclusively by Company. In addition, Company shall be entitled to post feedback at the Website (and/or allows others to do so), both positive and negative, regarding any User. You acknowledge and agree that the applicable supplier(s) of any third party software included within the Website shall own all worldwide rights, title and interest in and to such third party software (and any intellectual property rights therein).",
      ],
    },
    {
      title: "EXPORT CONTROL",
      subtitles: [
        "You understand and acknowledge that Software elements of Materials on the Website may be subject to the regulations by the related agencies or laws of a Particular country/state/nation, which may prohibits export or diversion of software and other good to certain country and third party. Thus, You will not assist or participate in such diversion or other violation of the applicable laws and regulations",
        "You agree that no ne of the Materials which are being or will be acquired for, shipped, transferred, or re-exported, directly or indirectly, to proscribed or embargoed countries or their nationals or be used for proscribed activities",
      ],
    },
    ,
    {
      title: "RELATINOSHIP",
      subtitles: [
        "Nothing in this Agreement shall be deemed to constitute, create, imply, give effect to otherwise denote a partnership, employer-employee relationship, principal -agent relation, joint venture, or formal business of any kind, and the rights and obligations of the parties shall be limited to those expressly set forth herein",
      ],
    },
    {
      title: "NOTICE",
      subtitles: [
        "All notices and communications from You to Company in relation to the Website (including the termination of any of the services etc.) shall be in writing. You shall be deemed to have been given a notice by the Company if sent by an email, SMS or posted within the Website. Notice shall be deemed to have been served 48 (forty eight) hours after it has been sent, dispatched, displayed, as the case may be, unless, where notice has been sent by email, it comes to the knowledge of the sending party, that the email address is invalid",
      ],
    },
    ,
    {
      title: "FORCE MAJEURE",
      subtitles: [
        "The Company is not liable for failure to perform any of its obligations if such failure is as a result of Force Majeure Situation e.g. Acts of God (including fire, flood, earthquake, storm, hurricane or other natural disaster), war, invasion, act of foreign enemies, hostilities (regardless of whether war is declared), civil war, rebellion, revolution, insurrection, military or usurped power or confiscation, terrorist activities, nationalisation, government sanction, blockage, embargo, labor dispute, strike, lockout, robbery, theft of data or any interruption or any failure of electricity or server, system, computer, internet or telephone service",
      ],
    },
    {
      title: "ARBITRATION PROVISIONS",
      subtitles: [
        "All dispute between the Parties related to this Agreement or Website shall be first compulsorily be attempted to be resolved by the Parties in good faith.",
        "If Parties are unable to resolve the dispute than parties agree that dispute shall be referred to and finally resolved by arbitration under the Indian Rules. The number of arbitrator shall be one, and the seat or legal place of arbitration shall at Gujarat",
        "There shall be no wavier of the right of arbitration, unless such wavier is provided affirmatively and in writing by the waiving Party to the other party. There shall be not implied waiver of this right to arbitration.",
        "No action, regardless of form, arising out of or in conjunction with the subject matter of this Agreement, except for claims involving Intellectual Property rights, claim to recover outstanding amount to Us and claims for indemnification, may be brought by a Party more than One (1) year after the cause of action arose",
      ],
    },
    {
      title: "OTHER MISELLANEOUS PROVISIONS",
      subtitles: [
        "If any provision of the Terms and Conditions is determined by laws or court of law to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of this Agreement shall continue to be in full force and effect",
        "This Agreement constitutes the entire agreement between Company and You and supersedes and extinguishes all previous agreements, promises, assurances, representations, warranties and undertakings, whether written or oral",
        "Failure by the Company to exercise any right or remedy under this Agreement does not constitute a waiver of that right or remedy.",
        "Company may assign any of its responsibilities/obligations to any other Person without notice to You, at its sole discretion. However, You shall not assign, sub-license or otherwise transfer any of Your rights or obligations under this Agreement to any other party, unless a written consent is taken from Company.",
        "We make no representation that the Website or its services or any of the material contained therein are appropriate or available for use in other locations, and access to them from territories where their content may be illegal or is otherwise prohibited. Those who choose to access the Website and Services from such location do so on their own initiative and are solely responsible for determining compliance with all applicable local laws.",
      ],
    },
  ];

  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h2
                style={{
                  color: APP_COLORS.APP_DARK_2,
                  paddingBottom: "10px",
                  paddingTop: "50px",
                  textTransform: "uppercase",
                }}
              >
                TERMS and CONDITIONS
              </h2>

              {titles.map((titleObj, idx) => (
                <div key={idx}>
                  <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>{`${idx + 1}. ${
                    titleObj.title
                  }`}</h6>
                  {titleObj.subtitles.map((subtitle, subIndex) => (
                    <p
                      style={{ color: APP_COLORS.APP_BLACK }}
                      key={subIndex}
                    >{`${idx + 1}.${subIndex + 1} ${subtitle}`}</p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
